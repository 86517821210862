import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

const useHotjar = (hotjarId: string, hotjarSnippetVersion: string) => {
  useEffect(() => {
    hotjar.initialize(parseInt(hotjarId, 10), parseInt(hotjarSnippetVersion, 10));
  }, []);
};

export default useHotjar;
