import 'styles/globals.scss';

import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AnalyticsProvider } from '@/hooks/useAnalytics';
import useHotjar from '@/hooks/useHotjar';

const queryClient = new QueryClient();

export function reportWebVitals(metric: any) {
  switch (metric.name) {
    case 'FCP':
      break;
    case 'LCP':
      break;
    case 'CLS':
      break;
    case 'FID':
      break;
    case 'TTFB':
      break;
    case 'INP':
      break;
    default:
      break;
  }
}

export default function App({ Component, pageProps }: AppProps) {
  useHotjar(process.env.HOTJAR_ID as string, process.env.HOTJAR_SNIPPET_VERSION as string);

  return (
    <AnalyticsProvider writeKey={process.env.SEGMENT_WRITE_KEY as string}>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
    </AnalyticsProvider>
  );
}
