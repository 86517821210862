import { AnalyticsBrowser } from '@segment/analytics-next';
import React from 'react';

const AnalyticsContext = React.createContext<AnalyticsBrowser>(undefined!);

type Props = {
  writeKey: string;
  children: React.ReactNode;
};

export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const analytics = React.useMemo(() => AnalyticsBrowser.load({ writeKey }), [writeKey]);

  React.useEffect(() => {
    if (analytics) {
      analytics.ready(() => {
        analytics.page(window.location.pathname, {
          key: 'website_page_view',
          url: window.location.href,
          referrer: document.referrer,
          search: window.location.search,
        });
      });
    }
  }, [analytics]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }

  return result;
};
